import React, { Fragment, useState } from "react";
import { Form } from "react-bootstrap";
import queryString from "query-string";
import classes from "./DownloadForm.module.css";
import sendIcon from "../images/sendicon.png";

const DownloadForm = () => {
  const myId = queryString.parse(window.location.search).id;

  const video = process.env.PUBLIC_URL + "/uploads/" + myId + "#t=0.1";

  const [downloaded, setDownloaded] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("take me to the other place");
    fetch(video, {
      method: "GET",
      headers: {
        "Content-Type": "application/mp4",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `JustPlaneCrazy.mp4`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setDownloaded(true);
      });
  };

  return (
    <Fragment>
      {downloaded ? (
        <div className={classes.sendIcon}>
          <img src={sendIcon} alt="send icon" />

          <h1 className={classes.purpleText}>Thank You!</h1>
          <p className={classes.grayText}>Enjoy your video.</p>
        </div>
      ) : (
        <div>
          {" "}
          <div className={classes.videoContainer}>
            <video width="100%" height="100%" playsInline controls>
              <source src={myId === undefined ? "" : video} type="video/mp4" />
            </video>
          </div>
          <div className="lowerBox">
            <Form onSubmit={submitHandler}>
              <div className={classes.btnContainer}>
                <button className={classes.btnDownload} onClick={submitHandler}>
                  Download Video
                </button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default DownloadForm;
