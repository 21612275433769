import Header from "./compontents/Header";
import DownloadForm from "./compontents/DownloadForm";
import { Stack } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Stack>
        <Header />
        <DownloadForm />
      </Stack>
    </div>
  );
}

export default App;
